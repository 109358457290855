<template>

	<div class="main-contents">
		
		<div class="tit">프로젝트 상세보기<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="$router.go(-1)"/></div>
		<div class="contents-body demand">

			<ProjectInfo :projectSeq="projectSeq" type="101" @goList="$router.go(-1)"/>

		</div>
	</div>

</template>

<script>
import ProjectInfo from '@/components/highpro/ProjectInfo.vue';

export default {

	components : { ProjectInfo },

	data() {
		return {
			projectSeq : 0,			
		};
	},
	beforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
		if(this.$route.query.projectSeq != undefined && this.$route.query.projectSeq != '') {
			this.projectSeq = Number(this.$route.query.projectSeq);
		}		
	},
	mounted() {
		// console.log('mounted');
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {

	},
};
</script>
